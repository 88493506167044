import React from 'react'
import './bids.css'
import { AiFillHeart,AiOutlineHeart } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Bids = ({title, data}) => {
  return (
    <div className='bids section__padding' id='jelajah'>
      <div className="bids-container">
        <div className="bids-container-text">
          <h1>{title}</h1>
        </div>
        <div className="bids-container-card">
          {data.map((item, index) => {
            return (
              <div key={index} className="card-column" >
                <Link to={`/jelajah/`+item.slug}>
                  <div className="bids-card">
                    <div className="bids-card-top">
                      <div className='fill'>
                        <img src={item.link_foto} alt=""  />
                      </div>
                      
                          <p className="bids-title">{item.judul}</p>
                     
                    </div>
                    <div className="bids-card-bottom">
                      {/* <p>1.25 <span>ETH</span></p> */}
                      <p> <AiFillHeart /> 92</p>
                    </div>
                  </div>
                </Link>
              </div>
              )
            }
          )}
        </div>
      </div>
      <div className="load-more">
        <button>Load More</button>
      </div>
    </div>
  )
}

export default Bids
