import React from 'react';
import './item.css'
import {  Link } from "react-router-dom";

const Item = (props) => {

  const [data, setData] = React.useState([]);

  const getData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API}/api/news/get/list?limit=1&kategori=profile`);
      const json = await res.json()
      if (json.status === true) {
        setData(json.data)
        props.dataCall(json.data[0].link_foto)
      }
      
    } catch (error) {
        console.log(error);
    }
   
  }

  React.useEffect(() => {
    getData();
  },[])




  return( 
    <>
    {data.map((item, index) => {
       return (
        <div className='item section__padding' key={index}>
        <div className="item-image">
          <img src={item.link_foto} alt={item.slug} height={50}/>
        </div>
          <div className="item-content">
            <div className="item-content-title">
              <h1>{item.judul}</h1>
            </div>
            <div className="item-content-detail">
              <p>{item.meta_description}</p>
            </div>
            <div className="item-content-buy">
              {/* <button className="primary-btn">Buy For 4.5 ETH</button> */}
              <Link to={"/tentang/"+item.slug}> 
                <button className="secondary-btn">Mulai jelajah</button> 
               </Link>
            </div>
          </div>
      </div>
       )   
    })}
    </>
  )
};

export default Item;
