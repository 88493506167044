import React, {useState, useEffect} from 'react'
import DocumentMeta from 'react-document-meta';
import {useSearchParams} from 'react-router-dom';
import './detail.css'



const Details = (data) => {

    let [searchParams] = useSearchParams()
   
    let code = searchParams.get("code");

    console.log("CODE", code);

    const {judul, link_foto, konten, caption_foto, tags, meta_description, slug, penulis} = data.slug
    
    const meta = {
        title: judul,
        description: meta_description,
        canonical: window.location.href,
        meta: {
          charset: 'utf-8',
          name: {
            rating:'general',
            keywords: tags.replace("[",'').replace(']',''),
            author : penulis,
            title : judul,
            og_image : link_foto,
            og_secureImage : link_foto,
            og_imageAlt : caption_foto,
          }
        }
      };

      const postPoint = async (uuid) => {
        try {
          const res = await fetch(`${process.env.REACT_APP_API}/api/news/post/point`, 
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({"uuid_user" : uuid}),
            });
          
            const json = await res.json()
          
            console.log("POINT", json);
        
          
        } catch (error) {
            console.log(error);
        }
       
      }

      useEffect(() => {
        postPoint(code)
      },[]);

      

    return (
        <DocumentMeta {...meta}>
        <div className='detail'>
            <div className="detail-content">
                <div>
                    <div className='detail-image'> 
                        <img src={link_foto} alt={caption_foto} />
                    </div>
                    <div className='detail-judul'>  
                        <h1>{judul}</h1>
                    </div>
                    <div className='detail-isi'>
                    <div
                        className="detail-html"
                            dangerouslySetInnerHTML={{__html: konten}}
                        />
                    </div>
                
                </div>
            </div>
        </div>
        </DocumentMeta>
        )

}

export default Details;