import React, {useState, useEffect} from 'react'
import './header.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import seller1 from '../../assets/seller1.jpg'
import seller2 from '../../assets/seller2.png'
import seller3 from '../../assets/seller3.png'
import seller4 from '../../assets/seller4.png'
import seller5 from '../../assets/seller5.png'
import seller6 from '../../assets/seller6.jpg'
import verify from '../../assets/verify.png'
import coin from '../../assets/coin.png'
import { Link  } from 'react-router-dom';
const Header = () => {

  const [getBanner, setBanner] = useState(null)
  const [dataTim, setDataTim] = useState([])
  
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide:true,
    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          swipeToSlide:true,
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          swipeToSlide:true,
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true,
        }
      }
    ]
  };

  const settingsBanner = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 2000,
    autoplaySpeed: 200,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 500,
        settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        }
      }
    ]  
  };
  const DataBanner = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API}/api/news/get/list?kategori=banner`);
      const json = await res.json()
      if (json.status === true) {
          setBanner(json.data)
      }
      
    } catch (error) {
      
    }
   
  }

  const DataTim = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API}/api/news/get/tim`);
      const json = await res.json()
      if (json.status === true) {
          setDataTim(json.data)
      }
      
    } catch (error) {
      
    }
   
  }

  useEffect(() => {
   DataBanner()
   DataTim()
  }, [])
  return (
    <div className='header section__padding' id='tentang'>


      <div className='header-bannner-atas'>
        
        <Slider {...settingsBanner}>
          {getBanner&&getBanner.map((item, index) => {
            return (<Link to={`/info/${item.slug}`} key={index}> 
            <div>
              <div style={{
                marginRight: 5,
                marginLeft: 5,
                  background: "grey", 
                  backgroundImage : `url("${item.link_foto}")`, 
                  height: "150px", 
                  borderRadius : '25px', 
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',}}>
              </div>
            </div></Link>)
          })}
        </Slider>
      </div>
      
      <div className="header-slider">
        <h1>Our Tim</h1>
        <Slider {...settings} className='slider'>
          {dataTim.map((item, index) => {
            return(
              <div className='slider-card' key={index}>
                <p className='slider-card-number'>{item.target}</p>
                <div className="slider-img">
                  <img src={item.link_gambar} alt="" height={80}/>
                  <img src={verify} className='verify' alt="" />
                </div>
                {/* <Link to={`/profile/Rian`}> */}
                <p className='slider-card-name'>{item.nama.substring(0,12)}</p>
                {/* </Link> */}
                <p className='slider-card-name'>{item.kota} - <span>{item.kec}</span></p>
              </div>
            )
          }
          )}
        </Slider>
      </div>
    </div>
  )
}

export default Header
