import React, {useState, useEffect}from 'react';
import {Header, Details, Bids} from '../../components'
import {useParams} from "react-router-dom";


const Detail = (props) => {

    const [dataDetail, setDataDetail] = useState();
    const [data, setData] = useState([])
    
    let {slug} = useParams();

    console.log("SLUG", slug);  

    const getDetail = async (slug) => {
        try {
          const res = await fetch(`${process.env.REACT_APP_API}/api/news/get/detail?slug=${slug}`);
          const json = await res.json()
          if (json.status === true) {
            setDataDetail(json.data)
          }

          console.log(json);
          
        } catch (error) {
            console.log(error);
        }
       
    }

    const getData = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API}/api/news/get/list?limit=4`);
        const json = await res.json()
        if (json.status === true) {
          setData(json.data)
        }
  
        console.log(json);
        
      } catch (error) {
          console.log(error);
      }
     
  }

    useEffect(() => {
        getDetail(slug);
        getData()
        window.scrollTo(0, 0)
    }, [slug]);

  return (
  <div>
    {dataDetail&& <Details slug={dataDetail}/>}
    <Bids data= {data} title={"Jelajah yang lainnya"}/>
  </div>
  );
};

export default Detail;
