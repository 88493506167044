import React from 'react'
import './footer.css'
import nftlogo from '../../assets/logo.png'
import { AiOutlineInstagram,AiOutlineTwitter, } from "react-icons/ai";
import { RiDiscordFill } from "react-icons/ri";
import { FaTelegramPlane } from "react-icons/fa";
import { HashLink } from 'react-router-hash-link';
const Footer = () => {
  return (
    <div className='footer section__padding'>
      <div className="footer-links">
       
        <div className="footer-links_div">
          <h4>{process.env.REACT_APP_NAME}</h4>
          <HashLink  smooth to="/#tentang">
              <p>Tentang</p>
          </HashLink>
          <HashLink  smooth to="/#jelajah">
              <p>Jelajah</p>
          </HashLink>
          <HashLink  smooth to="/#kotak">
              <p>Kontak</p>
          </HashLink>
         
          
        </div>
        <div className="footer-links_div">
          <h4>Support</h4>
          <p>Legal</p>
          <p>Privacy policy</p>
        </div>
      </div>
      <div className="footer-copyright">
        <div>
        <p> © {(new Date().getFullYear())} {process.env.REACT_APP_NAME}. All Rights Reserved</p>
        </div>
        <div>
          <AiOutlineInstagram size={25} color='white' className='footer-icon' />
          <AiOutlineTwitter size={25} color='white' className='footer-icon'/>
          <RiDiscordFill size={25} color='white' className='footer-icon'/>
          <FaTelegramPlane size={25} color='white'  className='footer-icon' />
        </div>

      </div>
    </div>
  )
}

export default Footer
