import React from 'react'
import './privacy.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


const Remove = () => {

  return (
    <div className='header section__padding' id='tentang'>

      <div className='header-bannner-atas'>
        <div className="continer">
            <div className="row justify-content-md-center">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-12 order-1 order-md-0">
                    <div className="row mb-3 ">
                      <div className="col-md-12 ">
                        <div className="fb-like" data-share="true" data-width="450" data-show-faces="true">

                        </div>
                      </div>
                      <div className="col-12 col-md-12 p-4">
                        <h1>Saya ingin menutup/menghapus akun</h1>
                        <h2>Saya ingin menutup akun ini karena saya punya akun yang lain</h2>
                        <p>
                           Kamu bisa kok mengganti nomor telepon di akun ini dengan nomor telepon yang terdaftar di akun Tiara kamu yang lain tanpa perlu menutupnya. Pilih opsi yang tepat dan isi formnya, kami siap membantu kamu. 
                        </p>
                        <h2>Saya tidak ingin menggunakan aplikasi</h2>
                        <p>Jika kamu merasa sedang tidak membutuhkan layanan kami, kamu bisa keluar tanpa harus menutup akunmu, dan masuk kembali kapanpun kamu merasa membutuhkan layanan Gojek. Namun jika kamu sudah yakin ingin menutup akunmu, silakan ikuti salah satu dari langkah berikut ini:</p>
                        <ul>
                          <li>
                              Bagi pengguna iOS, silakan buka aplikasi, lalu ke halaman Profil Saya → Hubungi kami → Permohonan hapus akun
                          </li>
                          <li>
                              Bagi pengguna Android, silakan buka aplikasi, lalu ke halaman Profil Saya → Hubungi kami → Permohonan hapus akun
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
    </div>)
    }



export default Remove;
