import React from 'react';
import {Bids, Header} from '../../components'
import Item from '../item/Item'; 
import DocumentMeta from 'react-document-meta';
import GoogleMapReact from 'google-map-react';
import "./home.css";
import dataJson from './map.json'




const Home = () => {

  const [data, setData] = React.useState([]);
  const [lokasi, setLokasi] = React.useState([]);
  const [image, setImage] = React.useState('');

  const getData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API}/api/news/get/list?limit=4`);
      const json = await res.json()
      if (json.status === true) {
        setData(json.data)
      }

      
    } catch (error) {
        console.log(error);
    }
   
  }

  const getLokasi = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API}/api/wilayah/get/list/lokasi`);
      const json = await res.json()
      if (json.status === false) {
        setLokasi(json.data)
      }
      
    } catch (error) {
        console.log(error);
    }
   
  }

const defaultProps = {
  center: {
    lat: -6.8661546,
    lng: 107.0446641
  },
  zoom: 11
};



const AnyReactComponent = ({ key, lat, lng,link }) => (
  <div className='alerts-border' 
        key={key}
        style={{
          height : '50px',
          width : '50px',
          padding: '15px 10px',
          display: 'inline-flex',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '100%',
          overflow : 'hidden',
          transform: 'translate(-50%, -50%)'
    }}>
    <img src={link} alt="" height={50}/>
  </div>
);

  React.useEffect(() => {
    getData();
    getLokasi();
  }, [image]);

  const meta = {
      title:process.env.REACT_APP_NAME ,
      description: `${process.env.REACT_APP_NAME}  adalah sosok pemimpin yang merakyat,berani dan tegas. Komitmen kerakyatan diwujudkan dalam program yang nyata.`,
      canonical: window.location.href,
      meta: {
      charset: 'utf-8',
      name: {
        rating:'general',
        keywords: "dprri, kabupaten, kota, kecamatan, dewan, partai, merakyat, agama, islam, pendidikan, politik",
        author : process.env.REACT_APP_NAME,
        title : process.env.REACT_APP_NAME,
        'og:type' : "article",
        'og:title' : process.env.REACT_APP_NAME,
        'og:image' : image,
        'og:description' : `${process.env.REACT_APP_NAME}  adalah sosok pemimpin yang merakyat,berani dan tegas. Komitmen kerakyatan diwujudkan dalam program yang nyata.`, 
        'og:locale': "id_ID",
        'og:url' : image,
        'fb:app_id' :"1334122753825525",
        og_image :image,
        og_secureImage : image,
        og_imageAlt : process.env.REACT_APP_NAME,
      }
    }
    }

  return(
    <DocumentMeta {...meta}>
      <Header />
      <Item dataCall = {(data) => {
        setImage(data)
      }}/>
      <div id='kontak' className='home-map'>
          <GoogleMapReact
            options={{
                  styles: dataJson
              }}
              bootstrapURLKeys={{ key: "AIzaSyAbqR17b0QAB8KNtGLjAyyYNkdWGlP5s4U" }}
              defaultCenter={defaultProps.center}
              defaultZoom={8}
            >
              {lokasi.map((item, index) => {
                return (
                  <AnyReactComponent
                    key={index}
                    lat={item.latitude}
                    lng={item.longitude}
                    link={item.link_gambar}
                  />
                )
              }
              )}

          </GoogleMapReact>
      </div>
      <Bids 
        title="Jelajah" 
        data={data} />
    </DocumentMeta>)
}

export default Home;
