import React from 'react';
import {Privacy} from '../../components'
import "./Privacy.css";

const PrivacyDoc = ()=>{
    return(
        <Privacy/>
    )
}

export default PrivacyDoc;