import React from 'react';
import {Remove} from '../../components'
import "./Privacy.css";

const RemoveDoc = ()=>{
    return(
        <Remove/>
    )
}

export default RemoveDoc;